import React from 'react'
import { Layout } from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { HeadFC } from 'gatsby'
import { SEO } from '../components/SEO'

const GalleryPage = () => {
  return (
    <Layout>
      <div className="text-box">
        <h1>"Le transfo", Redon</h1>
        <h2>Louis Jamin et Irénée de Poulpiquet</h2>
        <div className="gallery">
          <div>
            <div className="image">
              <StaticImage
                src={'../images/gallery-1.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="gallery"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
              />
              <figcaption>Photo : Louis Jamin</figcaption>
            </div>
            <div className="image">
              <StaticImage
                src={'../images/gallery-2.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="gallery"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
              />
              <figcaption>Photo : Irénée de Poulpiquet</figcaption>
            </div>
          </div>
          <div>
            <div className="image">
              <StaticImage
                src={'../images/gallery-3.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="gallery"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
              />
              <figcaption>Photo : Irénée de Poulpiquet</figcaption>
            </div>
            <div className="image">
              <StaticImage
                src={'../images/gallery-4.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="gallery"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
              />
              <figcaption>Photo : Louis Jamin</figcaption>
            </div>
          </div>
        </div>

        <h1>Temple de Sittanandar Swami</h1>
        <h2>Pondichéry, été 2016</h2>

        <div className="gallery">
          <div className="image">
            <StaticImage
              src={'../images/gallery-5.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="gallery"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
          </div>
          <div className="image">
            <StaticImage
              src={'../images/gallery-6.webp'}
              placeholder="blurred"
              formats={['auto', 'webp', 'avif']}
              alt="gallery"
              transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
            />
          </div>
        </div>

        <h1>Poésie dansée, Pankaj Mistry</h1>
        <h2>Pondichéry, janvier 2011</h2>
        <div className="gallery">
          <div>
            <div className="image">
              <StaticImage
                src={'../images/gallery-7.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="gallery"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
              />
              <figcaption>Photo : Pankaj Mistry</figcaption>
            </div>
            <div className="image">
              <StaticImage
                src={'../images/gallery-8.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="gallery"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
              />
              <figcaption>Photo : Pankaj Mistry</figcaption>
            </div>
          </div>
          <div>
            <div className="image">
              <StaticImage
                src={'../images/gallery-9.webp'}
                placeholder="blurred"
                formats={['auto', 'webp', 'avif']}
                alt="gallery"
                transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
                quality={100}
              />
              <figcaption>Photo : Pankaj Mistry</figcaption>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default GalleryPage
export const Head: HeadFC = () => <SEO title="Lakshmi - Galerie" pathname="/galerie"></SEO>
